@import "./variables.scss";

body {
  margin: 0;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $Navy;
  color: $Slate !important;
  line-height: 1.3;
  font-family: $MainFont;
  font-size: 20px;
}
.section-box {
  padding: 130px 0;
}
.section {
  background: $Navy;
}

.code-font {
  font-family: $CodeFont;
}

.e-font {
  font-family: $MainFont;
}

.section-title {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 10px 0 40px;
  width: 100%;
  white-space: nowrap;
  font-size: 32px;
  font-weight: 600;
  color: $LightestSlate;
  font-family: $MainFont;
  margin: 0 0 10px 0;

  .n-section-title {
    margin-right: 10px;
    font-family: $CodeFont;
    font-weight: normal;
    color: $Green;
    font-size: 20px;
    position: relative;
    bottom: 4px;
  }

  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 300px;
    background-color: $LightestNavy;
    position: relative;
    top: -5px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.main-btn {
  color: $Green;
  background-color: transparent;
  border: 1px solid $Green;
  border-radius: 3px;
  font-size: 13px;
  font-family: $CodeFont;
  line-height: 1;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 1.25rem 1.75rem;

  &:hover,
  &:focus,
  &:active {
    background-color: rgba(100, 255, 218, 0.07);
    outline: none;
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $Green;
  }
}

.underline {
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: $Green !important;
}
.underline:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 3px;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: $Green;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0%;
}
.underline:hover:after {
  width: 100%;
  left: 0;
}

@media (min-width: 768px) and (max-width: 1100px) {
  .proyect-container {
    margin: 30px 0 !important;
    height: 385px !important;
  }
}
@media (min-width: 1100px) and (max-width: 1300px) {
  .container {
    max-width: 950px;
  }
}

@media (min-width: 992px) and (max-width: 1100px) {
  .container {
    max-width: 800px;
  }
}

@media (min-width: 880px) and (max-width: 992px) {
  .container {
    max-width: 700px;
  }
}

@media (min-width: 768px) and (max-width: 880px) {
  .container {
    max-width: 600px;
  }
}

@media (max-width: 768em) {
  .section-title {
    font-size: 24px;
  }
  .container {
    width: 90%;
  }
}

@media (max-width: 1000px) {
  .banner h2,
  .banner h3,
  .big-title {
    font-size: 70px !important;
  }
  .big-subtitle {
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  .banner h2,
  .banner h3,
  .big-title {
    font-size: 60px !important;
  }
}
@media (max-width: 480px) {
  .banner h2,
  .banner h3,
  .big-title {
    font-size: 50px !important;
  }
}
@media (max-width: 376px) {
  .banner h2,
  .banner h3,
  .big-title {
    font-size: 40px !important;
  }
}

@media (max-width: 30em) {
  body {
    font-size: 18px;
  }
}

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import '~@angular/material/prebuilt-themes/indigo-pink.css'; 

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
